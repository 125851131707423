import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import KYCLayout from '../components/v2/kyc/KYCLayout';
import Layout from '../components/v2/Layout';

const KYC = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('KYC/AML')}>
      <KYCLayout />
    </Layout>
  );
};

export default KYC;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
