import React from 'react';

import KYCLandingFormLazyLoader from './KYCLandingLoader';

const KYCLanding = () => {
  return (
    <div className="max-w-[560px] mx-auto pt-40 md:pt-80 pb-40 md:pb-64">
      <KYCLandingFormLazyLoader />
    </div>
  );
};

export default KYCLanding;
