import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

const Hero = () => {
  return (
    <div className="bg-engine-green text-white pt-32 md:pt-48 lg:pt-80 pb-32 md:pb-56 lg:pb-80 relative overflow-hidden">
      <img
        src="/images/paima-engine-hero-bg.webp"
        alt=""
        className="absolute right-0 md:-bottom-[10vw] 2xl:right-[calc((100vw-1114px)/2-13vw)] lg:max-w-[600px] lg:max-h-[600px] xl:max-w-[800px] xl:max-h-[800px] md:max-w-[384px] md:max-h-[384px] z-0 hidden md:block"
      />
      <div className="container-slim z-10 relative">
        <h1 className="text-32 sm:text-40 md:text-64 font-bold font-heading text-center">
          <Trans>KYC/AML</Trans>
        </h1>
      </div>
    </div>
  );
};

export default Hero;
