import React from 'react';

import CookieConsentBanner from '../../CookieConsentBanner';
import Footer from '../Footer';
import Header from '../header/Header';
import Hero from './Hero';
import KYCLanding from './KYCLanding';

const KYCLayout = () => {
  return (
    <div className="relative">
      <CookieConsentBanner />
      <Header />
      <Hero />
      <KYCLanding />
      <Footer />
    </div>
  );
};

export default KYCLayout;
